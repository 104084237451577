import { media, spacing } from '@elseu/sdu-titan';
import dynamic from 'next/dynamic';
import { NextSeo } from 'next-seo';
import styled from 'styled-components';

import { useSiteConfig } from '../context/SiteConfigProvider';

const DefaultHomePage = dynamic(
  () => import(/* webpackChunkName: "DefaultHomePage" */ '../components/HomePage/DefaultHomePage'),
);

const SearchHomePage = dynamic(
  () => import(/* webpackChunkName: "SearchHomePage" */ '../components/HomePage/SearchHomePage'),
);

const Container = styled.div`
  position: relative;
  margin-top: ${spacing(6)};
  ${media.greaterThan('s')`
    margin-top: ${spacing(6)};
  `}
`;

function HomePage() {
  const { featureFlags } = useSiteConfig();

  return (
    <>
      <NextSeo title="Home" />
      <Container>
        {featureFlags.WITH_HOMEPAGE_SEARCH ? <SearchHomePage /> : <DefaultHomePage />}
      </Container>
    </>
  );
}
export { HomePage };
